<template>
  <div class="report-header">
    <div class="row">
      <div class="col">
        <div class="d-flex" v-if="accountNo">
          <p style="margin-inline-end: 4px">{{ $t("reports.code") }}:</p>
          <p>{{ accountNo }}</p>
        </div>
        <h6 v-if="type != 'general'">
          {{ filterdPeriod != "" ? filterdPeriod : headerAccountingPeriod }}
        </h6>
        <div class="d-flex" v-if="accountName">
          <p style="margin-inline-end: 4px">{{ $t("reports.report_name") }}:</p>
          <p>{{ accountName }}</p>
        </div>
      </div>
      <div class="col">
        <h5>
          {{ $i18n.locale == "ar" ? "هيئة المياه فرع " : "KSWC" }} -
          {{ $i18n.locale == "ar" ? branch_name : branch_name_en }}
        </h5>
        <h5>{{ reportName }}</h5>
      </div>
    </div>
    <div class="col"></div>
  </div>
</template>

<script>
export default {
  props: ["reportName", "filterdPeriod", "accountNo", "accountName", "type"],
  data() {
    return {
      branch_name: "",
      branch_name_en: "",
      headerAccountingPeriod: "durring ",
    };
  },
  methods: {
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
          resp.data.forEach((period) => {
            if (period.is_current) {
              this.headerAccountingPeriod += `${period.start_date} To ${period.end_date}`;
            }
          });
        }
      });
    },
  },
  created() {
    let current_user = JSON.parse(localStorage.currentUser);
    this.branch_name = current_user.branch_name;
    this.branch_name_en = current_user.branch_name_en;
    this.getAccountingPeriods();
  },
};
</script>
